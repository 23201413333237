import "./App.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
// import { Gallery } from "./components/gallery";
// import { Testimonials } from "./components/testimonials";
// import { Team } from "./components/Team";
import { Footer } from "./components/footer";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./fonts/font-awesome/css/font-awesome.css";
// import Hello from "./pages/1st_blog";
// import Blog2 from "./pages/2nd_blog";
// import Blog3 from "./pages/3rd_blog";
import PrivacyPage from "./pages/Privacy_Policy"; // Updated import statement
import TermsAndCondition from "./pages/terms_&_condition";
import Team2 from "./pages/team2";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Contact1 from "./pages/contact";
// import { Navigation1 } from "./pages/navigation1";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [hello, setHello] = useState(false);

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
     {/* <Navigation1/> */}
     <Navigation/>
     
      <Router>
        <Routes>
          <Route path='/pages/privacy_policy.jsx' element={<PrivacyPage setHello={setHello} />} />
          <Route path='/pages/terms_&_condition.jsx' element={<TermsAndCondition setHello={setHello} />} />
          <Route path='/pages/team2.jsx' element={<Team2 setHello={setHello} />} />
          <Route path='/pages/contact.jsx' element={<Contact1 setHello={setHello} data={landingPageData.Contact1} />} />
        </Routes>
      </Router>
      {
        hello === false ?
        <>
          
          <Header data={landingPageData.Header} />
          <Features data={landingPageData.Features} />
          <About data={landingPageData.About} />
          <Services data={landingPageData.Services} />
          {/* <Gallery data={landingPageData.Gallery} /> */}
          {/* <Testimonials data={landingPageData.Testimonials} /> */}
          {/* <Team data={landingPageData.Team} /> */}
          <Footer data={landingPageData.Footer} />
        </>
        : <></>
      }
    </div>
  );
};

export default App;
