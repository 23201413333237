import React from "react";
// import Contact1 from "./contact";
// import './contact.css';
// import "./fonts/font-awesome/css/font-awesome.css";
export const Footer2 = () => {
    return (
        <div>
            <div id="contact1" style={{marginTop: "180"}}>
                <div className="container">
                    <div className="col-md-3 col-md-offset-1 contact-info"></div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="social">
                                <div className="footerNav">
                                    <ul>
                                        <li><a href="/#Home" className="page-scroll">Home</a></li>
                                        {/* <li><a href="/pages/team2.jsx" className="page-scroll">Our Team</a></li> */}
                                        <li><a href="/pages/contact.jsx" className="page-scroll">Contact Us</a></li>
                                        <li><a href="/pages/privacy_policy.jsx">Privacy Policy</a></li>
                                        <li><a href="/pages/terms_&_condition.jsx">Terms & Conditions</a></li>
                                    </ul>
                                </div>
                                <ul>
                                        <li>
                                            <a href={"https://www.facebook.com/laathiTech"} target="_blank" rel="noopener noreferrer">
                                            <i className="fa fa-facebook"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href={"https://twitter.com/Laathicom"} target="_blank" rel="noopener noreferrer">
                                                <i className="fa fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href={"https://www.linkedin.com/company/tech-shy-private-limited/"} target="_blank" rel="noopener noreferrer">
                                                <i className="fa fa-linkedin"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href={"https://www.youtube.com/@laathitech"} target="_blank" rel="noopener noreferrer">
                                                <i className="fa fa-youtube"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href={"https://www.instagram.com/laathitech/"} target="_blank" rel="noopener noreferrer">
                                                <i className="fa fa-instagram"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href={"https://api.whatsapp.com/send/?phone=919119111053&text&type=phone_number&app_absent=0"} target="_blank" rel="noopener noreferrer">
                                                <i className="fa fa-whatsapp"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href={"mailto:help@laathi.com"} target="_blank" rel="noopener noreferrer">
                                                <i className="fa fa-envelope"></i>
                                            </a>
                                        </li>
                                    </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="footer" style={{marginTop:"10"}}>
                <div className="container text-center">
                    <p>
                        Copyright &copy; {new Date().getFullYear()} Laathi ™ {" "}
                    </p>
                </div>
            </div>
        </div>
    );
};