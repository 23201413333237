import React from 'react';
import "./team2.css";
import { Footer2 } from './footer2';

export const Team2 = ({ setHello }) => {
    return (
        <>
        <div className='main_div_c'>
            {setHello(true)}
            <div id="team" className="text-center">
                <div className="col-md-8 col-md-offset-2 section-title">
                    <h2>Meet the Team</h2>
                    <p>
                        "Meet the Heartbeats Behind Laathi: Our Dedicated Team of Visionaries!"
                    </p>
                </div>
                <div className="row1" style={{ paddingLeft: "2rem", paddingRight: "2rem", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    <div className="col-md-4 team2 thumbnail1" style={{ margin: "1rem", textAlign: "center" }}>
                        <img src="/img/team/dr-anamika.jpg" alt="Dr. Anamika Singh" style={{ height: "24rem", width: "24rem" }} />
                        <p className="caption1">DR. ANAMIKA SINGH</p>
                        <p className="text-blk position">Dr. Anamika Singh has done her masters in social work, another masters from USA in criminal justice and Ph.D in sociology. She has continuously worked on social issues and plays an important role at laathi.com to make it a company that brings positive change in the society.</p>
                    </div>
                    <div className="col-md-4 team2 thumbnail1" style={{ margin: "1rem", textAlign: "center" }}>
                        <img src="/img/team/navneet-kumar.jpg" alt="Navneet Kumar" style={{ height: "24rem", width: "24rem" }} />
                        <p className="caption1">NAVNEET KUMAR</p>
                        <p className="text-blk position">Navneet has an entrepreneurial experience of 15 years as he established various companies in Africa. He did his graduation in French language from JNU and speaks various languages fluently. He is an avid reader and works really hard to bring something from idea stage to a profit-making company.</p>
                    </div>
                    <div className="col-md-4 team2 thumbnail1" style={{ margin: "1rem", textAlign: "center" }}>
                        <img src="/img/team/dr-vireswar-kumar.jpg" alt="Dr. Vireshwar Kumar" style={{ height: "24rem", width: "24rem" }} />
                        <p className="caption1">DR. VIRESHWAR KUMAR</p>
                        <p className="text-blk position">Dr. Vireshwar works as faculty in the computer science department of highly reputed IIT Delhi. He works with us in the role of Mentor and gives us regular guidance related to technology, use of artificial intelligence, automation, and tech team building. His core research subjects are 5G network, car security, self-driven car safety, and drone automation.</p>
                    </div>
                    <div className="col-md-4 team2 thumbnail1" style={{ margin: "1rem", textAlign: "center" }}>
                        <img src="/img/team/batissmal.jpg" alt="Btissam El Morsli" style={{ height: "24rem", width: "24rem" }} />
                        <p className="caption1">BTISSAM EL MORSLI</p>
                        <p className="text-blk position">Btissam brings great insight from her country of residence, Italy and Morocco, and she has personally worked with various old people and took care of them. She understands the problem well and she is enthusiastic about the whole concept of Laathi.</p>
                    </div>
                    <div className="col-md-4 team2 thumbnail1" style={{ margin: "1rem", textAlign: "center" }}>
                        <img src="/img/team/hemant.jpg" alt="Hemant" style={{ height: "24rem", width: "24rem" }} />
                        <p className="caption1">HEMANT</p>
                        <p className="text-blk position" style={{ height: "10em"}}>M.TECH, CSE, IIT DELHI. Hemant plays an important role in coordinating on various tech-related subjects and gives his valuable insight. His back end data engineering helps us build a great product.</p>
                    </div>
                </div>
            </div>
        
            
        </div>
        <Footer2/>
        </>
    );
};

export default Team2;
